import React from 'react'

const Join = () => {
  return (
    <div className='bg-[#F2E3D0] py-40 flex justify-center'>
        <img src={require('../../Assets/join_desktop.png')}/>
    </div>
  )
}

export default Join