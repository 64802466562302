import React from 'react'
import '../About/about.css'
import Button from '../Button'
import ButtonText from '../Button/ButtonText'
const AboutBox = () => {
    return (
        <div className='relative'>
            <img src={require('../../Assets/about_box_bg.png')}/>
            <div className='absolute bottom-20 flex justify-center w-full'>
            <ButtonText title="Buy Now"/>
            </div>
        </div>
    )
}

export default AboutBox