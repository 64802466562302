import logo from './logo.svg';
import './App.css';
import Head from './Components/Head';
import Nav from './Components/Nav/Nav';
import Slider from './Components/Slider';
import About from './Components/About/About';
import Tokenomics from './Components/Tokenomics/Tokenomics';
import How from './Components/How/How';
import Roadmap from './Components/Roadmap/Roadmap';
import Join from './Components/Join/Join';

function App() {
  return (
    <div className="App max-w-[100vw] overflow-clip">
      <div className='relative'>
        <Nav />
        <Head />
      </div>
      <div>
        <Slider />
      </div>
      <About/>
      <Tokenomics/>
      <How/>
      <Roadmap/>
      <Join/>
    </div>
  );
}

export default App;
