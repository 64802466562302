import React from 'react'

const ButtonText = () => {
  return (
    <div className='relative'>
        <img src={require('../../Assets/button_kuro.png')}/>
        <div className='absolute inset-0 flex justify-center items-center '>
            <p className='pulang text-[34px] font-[400]'>Buy $KUROMI</p>
        </div>
    </div>
  )
}

export default ButtonText