import React from 'react'

const How = () => {
  return (
    <div className='bg-[#F2E3D0] py-20'>
        <div className='flex justify-center'>
            <img src={require('../../Assets/how.png')}/>
        </div>
        <div className='flex flex-wrap justify-center gap-6 mt-40'>
            <img src={require('../../Assets/1.png')}/>
            <img src={require('../../Assets/2.png')}/>
            <img src={require('../../Assets/3.png')}/>
            <img src={require('../../Assets/4.png')}/>
        </div>
        <div className='flex justify-center mt-80'>
            <img src={require('../../Assets/roadmap_text.png')}/>
        </div>
    </div>
  )
}

export default How