import React from 'react'
import './head.css'
const Head = () => {
  return (
    <div className='bg-home flex justify-center items-center '>
        <img src={require('../Assets/home.gif')} className='w-[90%] sm:w-[85%] md:w-[55%] xl:w-[45%]'/>
        <div className='absolute bottom-10'>
            <div className=' h-[68px] w-[655px] bg-[#F2E3D0] border-2 border-black'>

            </div>
        </div>
    </div>
  )
}

export default Head