import React from 'react'
import './about.css'
import AboutBox from '../AboutBox/AboutBox'
const About = () => {
  return (
    <div className='bg-[#FA88B3]  w-[100vw] p-20 h-full'>
        <div className='bg-about flex px-20 bg-[#F2E3D0] justify-between py-20'>
            <div className='flex justify-center items-center'>
                <img src={require('../../Assets/about.png')} />
            </div>
            <div>
                <AboutBox/>
            </div>
        </div>
    </div>
  )
}

export default About