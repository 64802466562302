import React from 'react'

const Roadmap = () => {
  return (
    <div className='bg-[#F2E3D0] bg-about min-h-[700px] w-[100vw] flex justify-center'>
        <img src={require('../../Assets/roadmap.png')} className='py-[120px]'/>
    </div>
  )
}

export default Roadmap