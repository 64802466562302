import React from 'react'

const Tokenomics = () => {
  return (
    <div className='bg-[#F2E3D0] bg-about min-h-[700px] w-[100vw]'>
        <div className='flex justify-center relative -top-[100px]'>
            <img src={require('../../Assets/kuronomics.png')}/>
        </div>
        <div className='flex justify-between px-[20%] relative -top-20'>
            <img src={require('../../Assets/tax.png')}/>
            <img src={require('../../Assets/supply.png')}/>
        </div>
        <div className='flex justify-center relative -top-40'>
            <img src={require('../../Assets/kuro_pic.png')}/>
        </div>
    </div>
  )
}

export default Tokenomics