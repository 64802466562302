import React from 'react'

const Button = (props) => {
    const { title, icon } = props
    return (
        <div className='relative'>
            <div className='absolute -top-[2px] -left-[2px] bg-[#F2E3D0] flex  h-[66px] w-[66px] border-2 border-black justify-center gap-2  items-center '>
                {title}
                {icon}
            </div>
            <div className='bg-black top-[8px] left-[8px] h-[70px] w-[70px]'>
                .
            </div>
        </div>
    )
}

export default Button