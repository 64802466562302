import React from 'react'
import './nav.css'
import Button from '../Button'
import '../../index.css'
const Nav = () => {
    return (
        <div className='flex justify-center'>
            <div className='bg-nav flex justify-between absolute top-8 h-[100px] w-[80%] items-center px-4'>
                <img className='h-[76px]' src={require('../../Assets/logo.png')} />
                <div className='flex gap-[39px]'>
                    <p className='pulang text-[32px] text-[black] uppercase'>Home</p>
                    <p className='pulang text-[32px] text-[black] uppercase'>About</p>
                    <p className='pulang text-[32px] text-[black] uppercase'>Kuronomics</p>
                    <p className='pulang text-[32px] text-[black] uppercase'>How to Buy</p>
                    <p className='pulang text-[32px] text-[black] uppercase'>Roadmap</p>
                </div>
                <div className='flex gap-4 '>
                    <Button title="" icon={<Telegram />} />
                    <Button title="" icon={<Twitter />} />
                </div>
            </div>
        </div>
    )
}

export default Nav;

const Telegram = () => {
    return (
        <img src={require('../../Assets/tg.png')} className='h-[36px] w-[43px]' />
    )
}
const Twitter = () => {
    return (
        <img src={require('../../Assets/x.png')} className='h-[36px] w-[43px]' />
    )
}

